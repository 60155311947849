import './App.css';
import { useState, useEffect } from 'react';
import { FaEnvelope, FaTelegramPlane } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive'

const logo = [
  ['open', 'open', 'open', 'open', 'open', 'open', 'open'],
  ['open', 'open', 'open', 'open', 'open', 'open', 'open'],
  ['open', 'open', 'open', 'open', 'open', 'open', 'open'],
  ['open', 'open', 'open', 'light', 'open', 'open', 'open'],
  ['open', 'dark', 'dark', 'light', 'dark', 'dark', 'dark'],
  ['open', 'dark', 'open', 'light', 'open', 'dark', 'open'],
  ['open', 'dark', 'dark', 'light', 'light', 'dark', 'open'],
  ['open', 'dark', 'open', 'dark', 'open', 'dark', 'open'],
  ['open', 'open', 'open', 'open', 'open', 'open', 'open'],
  ['open', 'open', 'open', 'open', 'open', 'open', 'open'],
  ['open', 'open', 'open', 'open', 'open', 'open', 'open'],
  ['open', 'open', 'open', 'open', 'open', 'open', 'open'],
];

const logosmall = [
  ['open', 'open', 'light', 'open', 'open', 'open'],
  ['dark', 'dark', 'light', 'dark', 'dark', 'dark'],
  ['dark', 'open', 'light', 'open', 'dark', 'open'],
  ['dark', 'dark', 'light', 'light', 'dark', 'open'],
  ['dark', 'open', 'dark', 'open', 'dark', 'open'],
];

const rands = new Array(12).fill(0).map(i => {
  return new Array(7).fill(0).map(j => Math.random() + 0.5);
});

const App = () => {
  const [percentage, setPercentage] = useState(0);
  const [percentage2, setPercentage2] = useState(0);
  const [contentInView, setContentInView] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-device-width: 1024px)'
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollable = window.document.body.offsetHeight - window.innerHeight;
      const scrolled = window.pageYOffset;
      const perc = Math.min(1, scrolled / scrollable * 2);
      const perc2 = Math.max(0, scrolled / scrollable * 2 - 1);
      
      setPercentage(perc);
      setPercentage2(perc2);
      if (perc >= 0.5) {
        setContentInView(true);
      } else if (scrolled === 0) {
        setContentInView(false);
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // console.log(percentage.toFixed(2), percentage2.toFixed(2));

  return (
    <>
      <div className="App">
        <section className="page1">
          <div className="header">
            <div className="logo-container-small">
              {logosmall.map((row, i) => {
                return row.map((block, j) => (
                  <div key={`block_${i}_${j}`} className={`logo-block-${block} small-block`} />
                ));
              })}
            </div>
            <div className="companyName small">
              <div className="title"><span className="al">AL</span><span>TRADERS</span></div>
              <div className="subtitle" >future-proof algorithmic trading</div>
            </div>
          </div>
          <div className="brandpage">
            <div className="logo-container" style={{transform: `translateY(${50 * percentage}vh) perspective(100px) rotateY(5deg)`}}>
              {logo.map((row, i) => {
                return row.map((block, j) => (
                  <div key={`block_${i}_${j}`} className={`logo-block-${block}`} style={{opacity: Math.max(0, 1 - rands[i][j] * (percentage/2)), transform: `scale(${Math.max(0.1, 1 - rands[i][j] * (percentage/2))}) translate3d(${rands[i][j] * (percentage) * -50}vh, ${rands[i][j] * (percentage/3) * -50}vh, ${rands[i][j] * (percentage) * -50}vh)`}} />
                ));
              })}
            </div>
            {isDesktop && (
              <div className="companyName">
                <div className="title" style={{transform: `translateX(${-350 * Math.min(1, Math.pow(percentage * 2, 1))}px)`}}><span className="al">AL</span><span>TRADERS</span></div>
                <div className="subtitle" style={{opacity: 1-percentage*3, transform: `translateX(${0 * percentage}px)`}}>future-proof algorithmic trading</div>
              </div>
            )}
            <div className="contentContainer" style={{transform: `translateY(${-100 * percentage}vh)`}}>
              <div className="textBlock">
                {/* <h1 className="h1"><span className={`masked ${contentInView ? 'activated' : ''}`}>ALTRADERS</span></h1> */}
                <p className={`masked ${percentage >= 0.5 ? 'activated' : ''}`}><b>Altraders is a proprietary trading firm.</b></p>
                <p className={`masked ${percentage >= 0.55 ? 'activated' : ''}`}>We provide liquidity to digital assets markets using our in-house built trading software. Our algorithmic trading strategies and price models operate autonomously around the clock, contributing to efficient and liquid digital asset markets.</p>
                <p className={`masked ${percentage >= 0.6 ? 'activated' : ''}`}>To enhance 24/7 trading, we operate globally from Rotterdam and Kuala Lumpur.</p>
                <p className={`masked ${percentage >= 0.65 ? 'activated' : ''}`}>Our team is a small, dedicated team consisting of individuals with diverse backgrounds.</p>
              </div>
            </div>
            <div className="teamWrapper">
              <div className="teamContainer" style={{transform: `translateX(${-(isDesktop ? 50 : 100) * percentage2}vw)`}}>
                <div className={`photoHeader masked dark ${percentage2 >= 0.5 ? 'activated' : ''}`}>
                  <p><b>Team</b></p>
                </div>
                <div className="photoBlock">
                  <div className="person">
                    <div className={`photo masked dark ${percentage2 >= 0.9 ? 'activated' : ''}`}>
                      <img src="/brigitta-partoredjo.png" alt="CEO - Brigitta Partoredja" className="personPhoto" />
                    </div>
                    <div className={`name masked dark ${percentage2 >= 0.7 ? 'activated' : ''}`}>Brigitta Partoredjo</div>
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}>Trading / Board member</div>
                    <div className="contact-icons">
                      <a href="mailto:b.s.partoredjo@altraders.nl" target="_blanc">
                        <FaEnvelope />
                      </a>
                      {/* <a href="#">
                        <FaTelegramPlane />
                      </a> */}
                    </div>
                  </div>
                  <div className="person">
                    <div className={`photo masked dark ${percentage2 >= 0.9 ? 'activated' : ''}`}>
                      <img src="/guido-van-beek.png" alt="CTO - Guido van Beek" className="personPhoto" />
                    </div>
                    <div className={`name masked dark ${percentage2 >= 0.7 ? 'activated' : ''}`}>Guido van Beek</div>
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}>Tech / Board member</div>
                    <div className="contact-icons">
                      <a href="mailto:guido@altraders.nl" target="_blanc">
                        <FaEnvelope />
                      </a>
                      {/* <a href="#">
                        <FaTelegramPlane />
                      </a> */}
                    </div>
                  </div>

                  <div className="person">
                    <div className={`photo masked dark ${percentage2 >= 0.9 ? 'activated' : ''}`}>
                      <img src="/robin-slakhorst.png" alt="CEO - Brigitta Partoredja" className="personPhoto" />
                    </div>
                    <div className={`name masked dark ${percentage2 >= 0.7 ? 'activated' : ''}`}>Robin Slakhorst</div>
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}>Head of institutional trading</div>
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}></div>
                    <div className="contact-icons">
                      <a href="mailto:robin@altraders.nl" target="_blanc">
                        <FaEnvelope />
                      </a>
                      {/* <a href="#">
                        <FaTelegramPlane />
                      </a> */}
                    </div>
                  </div>
                  <div className="person">
                    <div className={`photo masked dark ${percentage2 >= 0.9 ? 'activated' : ''}`}>
                      <img src="/reinier-kors.png" alt="CTO - Guido van Beek" className="personPhoto" />
                    </div>
                    <div><div className={`name masked dark ${percentage2 >= 0.7 ? 'activated' : ''}`}>Reinier Kors</div></div>
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}>Tech</div>
                    <div className="contact-icons">
                      <a href="mailto:reinier@altraders.nl" target="_blanc">
                        <FaEnvelope />
                      </a>
                      {/* <a href="#">
                        <FaTelegramPlane />
                      </a> */}
                    </div>
                  </div>

                  <div className="person">
                    <div className={`photo masked dark ${percentage2 >= 0.9 ? 'activated' : ''}`}>
                      <img src="/willem.png" alt="CEO - Brigitta Partoredja" className="personPhoto" />
                    </div>
                    <div className={`name masked dark ${percentage2 >= 0.7 ? 'activated' : ''}`}>Willem Kullberg</div><br />
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}>Trading</div>
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}></div>
                    <div className="contact-icons">
                      <a href="mailto:willem@altraders.nl" target="_blanc">
                        <FaEnvelope />
                      </a>
                      {/* <a href="#">
                        <FaTelegramPlane />
                      </a> */}
                    </div>
                  </div>
                  <div className="person">
                    <div className={`photo masked dark ${percentage2 >= 0.9 ? 'activated' : ''}`}>
                      <img src="/benjamin.png" alt="CTO - Guido van Beek" className="personPhoto" />
                    </div>
                    <div><div className={`name masked dark ${percentage2 >= 0.7 ? 'activated' : ''}`}>Benjamin van Casteren</div></div>
                    <div className={`position masked dark ${percentage2 >= 0.75 ? 'activated' : ''}`}>Trading</div>
                    <div className="contact-icons">
                      <a href="mailto:benjamin@altraders.nl" target="_blanc">
                        <FaEnvelope />
                      </a>
                      {/* <a href="#">
                        <FaTelegramPlane />
                      </a> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className={`icon-scroll ${percentage2 >= 1 ? 'hide' : 'show'}`}></div>
        </section>
        <section className="page2">
        </section>
      </div>
    </>
  );
}

export default App;
